import React from 'react';
import PropTypes from 'prop-types';
import { SocialSharingContainer } from './social-sharing.css';
import { FaFacebookF, FaTwitter, FaRegEnvelope } from 'react-icons/fa';
import { registerTrackEvent, trackEvent } from '../../helpers/tagManager';

const SocialSharing = ({ mailSubject }) => {
  const url =
    typeof window !== 'undefined' && window.location && window.location.href
      ? encodeURIComponent(window.location.href)
      : '';
  const pageTitle = typeof window !== 'undefined' ? document.title : url;

  return (
    <SocialSharingContainer>
      <a
        href={'https://www.facebook.com/sharer/sharer.php?u=' + url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={registerTrackEvent({
          category: 'SocialShare',
          action: 'Facebook',
          label: pageTitle,
        })}
      >
        <span className={'accesibility-features'}>Facebook</span>
        <FaFacebookF />
      </a>
      <a
        href={'https://twitter.com/share?url=' + url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={registerTrackEvent({
          category: 'SocialShare',
          action: 'Twitter',
          label: pageTitle,
        })}
      ><span className={'accesibility-features'}>Twitter</span>
        <FaTwitter />
      </a>
      <a
        href={'mailto:?subject=' + mailSubject + '&body=' + url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={registerTrackEvent({
          category: 'SocialShare',
          action: 'Email',
          label: pageTitle,
        })}
      >
        <span className={'accesibility-features'}>Mail-to</span>
        <FaRegEnvelope />
      </a>
      <button
        onClick={() => {
          typeof window !== 'undefined' && window.print();
          trackEvent({
            category: 'SocialShare',
            action: 'Print',
            label: pageTitle,
          });
        }}
      >
        <img src="/images/print_icon.png" alt="Print Icon" />
      </button>
    </SocialSharingContainer>
  );
};

SocialSharing.propTypes = {
  mailSubject: PropTypes.string.isRequired,
};

export default SocialSharing;
