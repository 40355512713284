import styled from 'styled-components';
import {
  black,
  borderGrey,
  darkText,
  lightGrayText,
  titlePink,
  white,
} from '../../../constants/theme';

export const DropdownDocs = styled.div`
  opacity: ${props => (props.isDisabled ? '0.4' : '1')};
  .dropdown-title {
    color: ${lightGrayText};
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 15px;
  }
  .dropdown {
    margin-top: 13px;
    .selector {
      margin: 0;
      padding: 0;
      border: none;
      background-color: transparent;
      color: ${darkText};
      border-bottom: 2px solid ${borderGrey};
      font-size: 16px;
      line-height: 27px;
      border-radius: 0;
      width: 100%;
      text-align: start;
      display: flex;
      justify-content: space-between;
      text-transform: none;
      opacity: 0.6;
      &.selected {
        border-bottom: 2px solid ${titlePink};
        opacity: 1;
      }
      svg {
        color: ${titlePink};
        opacity: 1;
      }
      &:focus {
        outline: none;
      }
      &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
      }
    }
    &.show {
      .selector {
        border-bottom: 2px solid ${titlePink};
        opacity: 1;
      }
    }
    .dropdown-menu {
      margin-top: 8px;
      width: 100%;
      padding: 16px;
      border-radius: 0;
      background-color: ${white};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
        0 30px 40px 0 rgba(0, 0, 0, 0.2);
      border: 0;
      max-height: 200px;
      overflow: auto;
      .dropdown-item {
        color: ${black};
        font-size: 16px;
        line-height: 27px;
        margin: 10px 0px;
        padding: 0px;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: ${titlePink};
          text-decoration: underline;
        }
        &:active {
          background-color: initial;
          outline: none;
        }
      }
    }
  }
`;
