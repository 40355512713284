import styled from 'styled-components';
import { titlePink, white } from '../../constants/theme';

export const SocialSharingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  a {
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: ${titlePink};
    border-radius: 50%;
    margin-bottom: 6px;
    margin-left: 10px;
    .accesibility-features {
      display: none !important;
    }
  }
  button {
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    margin-bottom: 6px;
    margin-left: 10px;
    background: transparent;
    :focus {
      outline: none;
    }
  }
  img {
    width: 100%;
  }
  svg {
    font-size: 16px;
    color: ${white};
  }
`;
