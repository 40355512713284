import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Container, NavLink, Row } from 'reactstrap';
import DownloadComplete from '../download-complete/download-complete';
import DropdownDocuments from '../dropdown-menu/dropdown-menu';
import { DocsSection, StudyResultsSection } from './download-section.css';
import { trackEvent } from '../../../helpers/tagManager';

const DownloadSectionResults = ({
  labels,
  attachments,
  onClickDownload,
  individualPatientData,
  CTGovResults,
}) => {
  const [selectedType, setSelectedType] = useState('');
  const [url, setUrl] = useState('');
  const [isCompletedDownload, setIsCompletedDownload] = useState(false);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    if (attachmentsList && selectedType) {
      setUrl('');
      const selectedAttachment = attachmentsList[selectedType];
      switch (true) {
        case selectedAttachment.length == 1 && selectedAttachment[0].text == '':
          setLanguages([{ ...selectedAttachment[0], text: 'English' }]);
          break;
        default:
          setLanguages(selectedAttachment);
          break;
      }
    }
  }, [selectedType]);

  const attachmentsList = getAttachmentsGroupedByType(attachments);

  const dropdownDocsProps = !attachmentsList
    ? null
    : {
        items: Object.keys(attachmentsList).map(type => {
          if (Array.isArray(attachmentsList[type])) {
            return { text: attachmentsList[type][0].typeDisplay, value: type };
          }
        }),
        preselectedValue: selectedType,
        setSelectedDocument: setSelectedType,
        title: labels.dropdownDocsTitle,
        placeholder: labels.dropdownDocsPlaceholder,
      };

  const dropdownLanguagesProps = !attachmentsList
    ? null
    : {
        items: languages || [],
        preselectedValue: url,
        setSelectedDocument: setUrl,
        title: labels.dropdownLanguageTitle,
        placeholder: labels.dropdownLanguagePlaceholder,
        isDisabled: !(Array.isArray(languages) && languages.length > 0),
      };

  const downloadCompleteProps = {
    labels: {
      title: labels.completeDownloadTitle,
      description: labels.completeDownloadDesc,
      button: labels.completeDownloadBtn,
    },
    setIsCompletedDownload: setIsCompletedDownload,
  };

  const handleClickDownload = ev => {
    onClickDownload(ev, selectedType);
    trackEvent({
      category: 'Download',
      action: 'download',
      label: selectedType,
    });
    setTimeout(() => {
      setIsCompletedDownload(true);
      setLanguages([]);
      setSelectedType('');
      setUrl('');
    }, 1000);
  };

  return (
    <Container fluid={true} className="p-0">
      <Row md={2} className="m-0">
        <Col className="p-0">
          {isCompletedDownload ? (
            <DownloadComplete {...downloadCompleteProps} />
          ) : (
            <DocsSection>
              <h3>{labels.title}</h3>
              {!(dropdownDocsProps && dropdownLanguagesProps) ? (
                <span className="not-available">{labels.notAvailable}</span>
              ) : (
                <>
                  <div className="dropdown-menus">
                    <DropdownDocuments {...dropdownDocsProps} />
                    <DropdownDocuments {...dropdownLanguagesProps} />
                  </div>
                  <NavLink
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="download-btn text-uppercase"
                    aria-disabled={url === '' ? true : false}
                    disabled={url === '' ? true : false}
                    onClick={ev => handleClickDownload(ev)}
                  >
                    {labels.downloadBtn}
                  </NavLink>
                </>
              )}
            </DocsSection>
          )}
        </Col>
        {(CTGovResults || individualPatientData) && (
          <Col className="p-0">
            <StudyResultsSection>
              {CTGovResults && (
                <div className="gov-results">
                  <p>{labels.descCTGovResults}</p>
                  <a
                    className="text-uppercase"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={CTGovResults.href}
                    onClick={ev => CTGovResults.onClick(ev)}
                  >
                    {labels.labelCTGovResults}
                  </a>
                </div>
              )}
              {individualPatientData && (
                <div className="patient-results">
                  <p>
                    {labels.descIndividualPatientData}
                    <a
                      className="text-uppercase"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={individualPatientData.href}
                      onClick={ev => individualPatientData.onClick(ev)}
                    >
                      {labels.labelIndividualPatientData}
                    </a>
                    {'.'}
                  </p>
                </div>
              )}
            </StudyResultsSection>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default DownloadSectionResults;

DownloadSectionResults.propTypes = {
  labels: PropTypes.shape({
    title: PropTypes.string.isRequired,
    dropdownDocsTitle: PropTypes.string.isRequired,
    dropdownDocsPlaceholder: PropTypes.string.isRequired,
    dropdownLanguageTitle: PropTypes.string.isRequired,
    dropdownLanguagePlaceholder: PropTypes.string.isRequired,
    downloadBtn: PropTypes.string.isRequired,
    descCTGovResults: PropTypes.string.isRequired,
    labelCTGovResults: PropTypes.string.isRequired,
    descIndividualPatientData: PropTypes.string.isRequired,
    labelIndividualPatientData: PropTypes.string.isRequired,
    completeDownloadTitle: PropTypes.string.isRequired,
    completeDownloadDesc: PropTypes.string.isRequired,
    completeDownloadBtn: PropTypes.string.isRequired,
    notAvailable: PropTypes.string.isRequired,
  }),
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      TypeInternal: PropTypes.string.isRequired,
      TypeDisplay: PropTypes.string.isRequired,
      TypeLookupValue: PropTypes.string,
      Title: PropTypes.string,
      Url: PropTypes.string.isRequired,
      ImportSource: PropTypes.string,
      Language: PropTypes.string.isRequired,
    })
  ),
  onClickDownload: PropTypes.func.isRequired,
  individualPatientData: PropTypes.shape({
    href: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  CTGovResults: PropTypes.shape({
    href: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
};

export const getAttachmentsGroupedByType = attachments => {
  if (!attachments || attachments.length <= 0) {
    return null;
  }

  const attachmentsObj = attachments.reduce((result, currentAttach) => {
    const documentType = currentAttach['TypeInternal'];
    result[documentType] = [
      ...(result[documentType] || []),
      {
        text: currentAttach.Language,
        value: currentAttach.Url,
        typeDisplay: currentAttach.TypeDisplay,
      },
    ];
    return result;
  }, {});

  return attachmentsObj;
};
