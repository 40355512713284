import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { DropdownDocs } from './dropdown-menu.css';

const DropdownDocuments = ({
  items,
  preselectedValue,
  setSelectedDocument,
  title,
  placeholder,
  isDisabled,
}) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(placeholder);

  useEffect(() => {
    if (!preselectedValue) {
      setSelectedValue(placeholder);
    }
  }, [preselectedValue]);

  return (
    <DropdownDocs isDisabled={isDisabled}>
      <span className="dropdown-title text-uppercase">{title}</span>
      <Dropdown
        isOpen={dropdownIsOpen}
        toggle={() => setDropdownIsOpen(!dropdownIsOpen)}
      >
        <DropdownToggle
          className={`selector ${selectedValue != '' ? 'selected' : ''}`}
          data-toggle="dropdown"
          disabled={isDisabled}
          tag="button"
          type="button"
          name="country"
          onKeyDown={() => setDropdownIsOpen(!dropdownIsOpen)}
        >
          {selectedValue}
          <IoIosArrowDown />
        </DropdownToggle>
        <DropdownMenu flip={false} direction="down">
          {items.map((item, index) => (
            <DropdownItem
              key={index}
              onClick={() => {
                setSelectedValue(item.text);
                setSelectedDocument(item.value);
              }}
            >
              {item.text}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </DropdownDocs>
  );
};

export default DropdownDocuments;

DropdownDocuments.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  preselectedValue: PropTypes.string.isRequired,
  setSelectedDocument: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

DropdownDocuments.defaultProps = {
  isDisabled: false,
};
