import styled from 'styled-components';
import {
  black,
  lightGrayText,
  locationHeader,
  titlePink,
  white,
} from '../../../constants/theme';

export const StudyResultsSection = styled.div`
  padding: 72px 48px 48px 48px;
  background-color: ${locationHeader};
  height: 100%;
  .gov-results {
    margin-bottom: 32px;
    p {
      margin-bottom: 16px;
    }
  }
  p {
    color: ${black};
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 27px;
  }
  a {
    color: ${black};
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 1.9px;
    line-height: 13px;
    border-bottom: 2px solid ${titlePink};
    &:hover {
      text-decoration: none;
      color: #a52a69 !important;
    }
    &:focus {
      outline: auto 1px;
    }
  }
`;

export const DocsSection = styled.div`
  padding: 48px 45px 48px 48px;
  h2 {
    color: ${black};
    font-family: Lato;
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 6px;
  }
  h3 {
    color: #482d83;
    font-family: Lato;
    font-size: 18px;
    font-weight: 900;
    line-height: 26px;
  }
  .not-available {
    color: ${lightGrayText};
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 15px;
  }
  .dropdown-menus {
    margin: 32px 0px;
    div + div {
      margin-top: 24px;
    }
  }
  .dropdown-menu-details {
    margin: 24px 0px;
  }
  .download-btn {
    max-width: 195px;
    padding: 12px 14px;
    background-color: ${titlePink};
    color: ${white};
    font-family: Lato;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: 2px;
    line-height: 13px;
    text-align: center;
    border: 0;
    border-radius: 0;
    &.disabled {
      opacity: 0.5;
    }
    &:hover {
      background-color: #a52a69;
    }
    &:focus {
      outline: 5 px auto;
    }
  }
`;
