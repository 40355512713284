import styled from 'styled-components';
import { black, titlePink, white } from '../../../constants/theme';

export const DownloadCompleted = styled.div`
  padding: 48px;
  min-height: 362px;
  &.details-complete {
    min-height: 298px;
    padding-top: 81px;
    h3 {
      margin-bottom: 6px;
    }
  }
  h3 {
    margin-bottom: 28px;
    color: #482d83;
    font-family: Lato;
    font-size: 18px;
    font-weight: 900;
    line-height: 26px;
  }
  p {
    color: ${black};
    font-family: Lato;
    font-size: 18px;
    line-height: 32px;
  }
  button {
    padding: 12px 14px;
    background-color: ${titlePink};
    color: ${white};
    font-family: Lato;
    font-size: 11px;
    letter-spacing: 2px;
    line-height: 13px;
    text-align: center;
    border: 0;
    border-radius: 0;
    &:hover {
      background-color: #a52a69;
    }
    &:focus {
      outline: 5 px auto;
    }
    &:active {
      background-color: ${titlePink} !important;
    }
    &:active:focus {
      box-shadow: none !important;
    }
  }
`;
