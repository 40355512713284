import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import { DownloadCompleted } from './download-complete.css';

const DownloadComplete = ({ labels, setIsCompletedDownload, className }) => {
  return (
    <DownloadCompleted
      className={`d-flex justify-content-between flex-column ${
        className ? className : ''
      }`}
    >
      <div>
        <h3>{labels.title}</h3>
        <p>{labels.description}</p>
      </div>
      <div>
        <Button
          className="text-uppercase"
          onClick={() => setIsCompletedDownload(false)}
        >
          {labels.button}
        </Button>
      </div>
    </DownloadCompleted>
  );
};

export default DownloadComplete;

DownloadComplete.propTypes = {
  labels: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }),
  setIsCompletedDownload: PropTypes.func.isRequired,
  className: PropTypes.string,
};
