export const trackAnalytics = (eventName, eventPayload) => {
  if (
    typeof window !== 'undefined' &&
    window.segmentAnalytics &&
    window.segmentAnalytics.track
  ) {
    window.segmentAnalytics.track(eventName, eventPayload);
  }
};

// This function will return just the needed properties for the search event
export const filterSearchEventProperties = (properties) => {
  const neededProperties = [
    'PageSize',
    'PageIndex',
    'SortField',
    'SortOrder',
    'SearchTerm',
    'Status',
    'Longitude',
    'Latitude',
    'AgeRanges',
    'Conditions',
    'AttachmentTypes',
    'Phases',
    'Gender',
    'HealthyVolunteer',
    'StudyTypes',
    'StudyResults',
    'MileRadius',
    'UseAndOperatorForFullTextKeyWordSearch',
    'zip',
    'country',
  ];
  const eventProperties = {};
  neededProperties.forEach((neededProp) => {
    if (Object.keys(properties).includes(neededProp)) {
      eventProperties[neededProp] =
        typeof properties[neededProp] === 'string' &&
        properties[neededProp].includes('~')
          ? properties[neededProp].split('~')
          : properties[neededProp];
    } else {
      eventProperties[neededProp] = '';
    }
  });
  return eventProperties;
};
